.home {
	header {
		grid-area: header;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding-top: 4vw;
		color: var(--color-titles);
        position: relative;
        z-index: 1;

		@media #{$small} {
			padding-top: 8vw;
		}

		h1 {
			font-size: 1rem;
			text-transform: uppercase;
			margin-bottom: 2vw;
			letter-spacing: 0.04em;
			transition: $transition-snap color;
			transform: $transform-moveUpSkew;
			will-change: transform, color;
		}

		h2 {
			font-family: $font-ibm;
			font-size: 1rem;
			font-style: italic !important;
			line-height: 1.5em;
			text-align: center;
			transition: $transition-snap color;
			transform: $transform-moveUpSkew;
			will-change: transform, color;
		}
	}

	nav {
		grid-area: nav;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: $transform-scale;
		transform-style: preserve-3d;
		will-change: transform;
        position: relative;
        z-index: 1;

		.wrapper {
			position: absolute;
			display: flex;
			align-items: center;
			flex-direction: row;
			transition: 800ms ease-out transform;
			transform-style: preserve-3d;
			will-change: transform;
			transform: translateX(0vw);
			padding: 0em 4em;
			overflow: hidden;

			@media #{$medium} {
				flex-direction: column;
				transform: translateX(0vw) !important;
			}

			&:hover > a,
			&:hover > .line {
				opacity: 0.5 !important;
				transform: scale(1.05, 1.05) !important;
			}

			.line {
				width: 4vw;
				height: 2px;
				margin-right: 5vw;
				margin-left: 5vw;
				background-color: var(--color-titles);
				transition: $transition-snap background-color;
				transform: $transform-moveUpSkew;
				will-change: transform, background-color;

				@media #{$medium} {
					width: 60vw;
					margin: 2vw 0;
				}

				@media #{$small} {
					width: 80vw;
					margin: 5vw 0;
				}
			}

			a {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				font-family: $font-compressed;
				font-weight: 700;
				text-transform: uppercase;
				transform: $transform-moveUpSkew;
				will-change: transform;

				&.snap {
					// transition: $transition-snap all;
					color: var(--color-titles);
					&:hover {
						transform: scale(1.08, 1.08) !important;
						opacity: 1 !important;
					}
				}

				.label {
					transition: $transition-snap letter-spacing;
					font-size: $font-size-biggest;
					line-height: 1em;
					will-change: letter-spacing;

					&:hover {
						letter-spacing: 0.05em;
					}
				}

				.num {
					font-size: 0.8rem;
					margin-top: 1.1vw;
					margin-left: 0.25vw;
					font-family: $font-condensed;
					writing-mode: vertical-rl;
				}
			}
		}
	}

	footer {
		grid-area: footer;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		flex-direction: row;
		padding-bottom: 4vw;
        position: relative;
        z-index: 1;

		@media #{$small} {
			padding-bottom: 8vw;
		}

		a {
			font-size: 14px;
			padding: 20px;
			font-weight: 500;
			color: var(--color-titles);
			transition: $transition-snap color;
			transform: $transform-moveUpSkew;
			will-change: transform, color;
		}
	}
}
