html,
body {
	width: 100%;
	height: 100%;
}

.grid {
	display: grid;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: var(--color-bg);
	transition: $transition-snap background-color;

	&.home {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-areas: "header" "nav" "footer";
		// background-color: $color-lightest;
	}

	&.work,
	&.projects,
	&.about,
	&.contact {
		grid-template-columns: auto 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas: "nav content";
		// background-color: $color-black;

		@media #{$medium} {
			grid-template-rows: auto 1fr;
			grid-template-columns: 1fr;
			grid-template-areas: "nav" "content";
		}

		.container {
			grid-area: content;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 3px;
			}

			&::-webkit-scrollbar:hover {
				width: 10px;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				background: $color-red;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: $color-red-dark;
			}

			.gridWrapper {
				padding-top: 4vw;
				padding-bottom: 10vw;
                padding-left: 10vw;
                width: 100%;
                overflow: hidden;

				@media #{$medium} {
					padding-top: 8vw;
					padding-bottom: 8vw;
				}

				@media #{$medium} {
					grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
				}

				.content {
					transform: $transform-scale;
					will-change: transform;
				}
			}
		}
	}
}
